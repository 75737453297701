import { observable, action, makeObservable, computed } from "mobx";
import {LZ, SystemUser, SystemUserAccess} from "../../models/user/user";
import {DisplayType} from "../../components/core/commons/price/price.helper";

export class AuthStore {
    static KEY_BEARER = "BEARER";

    @observable
    private _originalUsername: string | undefined;

    @observable
    private _registered: boolean = false;// !!localStorage.getItem("registered");

    @observable
    private _token: string = "";

    @observable
    private _user: SystemUser | null = null;

    @observable
    private _attributes: string[] = [];

    @observable
    private _inited: boolean = false;

    @observable
    private _isInvalidCredentials: boolean | null = null;

    @observable
    private _isLogging: boolean = false;

    @observable
    private _userBranch: string = "";

    @observable
    private _access: number = 0;

    @observable
    private _accesses: SystemUserAccess[] = [];

    @observable
    private _closed: boolean = false;

    @observable
    private _selectedCurrencyCode: string = "";

    @observable
    private _currencyCodes: string[] = [];

    @observable
    private _lz: LZ = {
        pt: DisplayType.COMMA,
        py: false
    };

    constructor() {
        makeObservable(this);
        this.setToken(this.getToken());
        // this.setFakeUser(this.getFakeUser());
    }

    private getToken() {
        const authCookie = document.cookie.split(";").find((item) => item.includes(AuthStore.KEY_BEARER));
        return authCookie ? authCookie.split("=")[1] : "";
    }

    @computed
    public get branch(): string {
        return this._userBranch;
    }

    private set userBranch(value: string) {
        this._userBranch = value;
    }

    @action
    public setUserBranch(value: string): void {
        this.userBranch = value;
    }

    @computed
    public get registered(): boolean {
        return this._registered;
    }

    private set isRegistered(value: boolean) {
        this._registered = value;
    }

    @computed
    public get closed(): boolean {
        return this._closed;
    }

    public set closed(value: boolean) {
        this._closed = value;
    }

    @computed
    public get selectedCurrencyCode(): string {
        return this._selectedCurrencyCode;
    }

    public set selectedCurrencyCode(value: string) {
        this._selectedCurrencyCode = value;
    }

    @computed
    public get currencyCodes(): string[] {
        return this._currencyCodes;
    }

    public set currencyCodes(value: string[]) {
        this._currencyCodes = value;
    }

    @action
    public setRegistered(value: boolean): void {
        this.isRegistered = value;
    }

    @computed
    public get logging(): boolean {
        return this._isLogging;
    }

    public set isLogging(value: boolean) {
        this._isLogging = value;
    }

    @computed
    public get isInvalidCredentials(): boolean | null {
        return this._isInvalidCredentials;
    }

    public set isInvalidCredentials(value: boolean | null) {
        this._isInvalidCredentials = value;
    }

    @computed
    public get user(): SystemUser | null {
        return this._user;
    }

    public set user(value: SystemUser | null) {
        this._user = value;
    }

    @computed
    public get attributes(): string[] {
        return this._attributes;
    }

    public set attributes(value: string[]) {
        this._attributes = value;
    }

    @computed
    public get inited(): boolean {
        return this._inited;
    }

    private set loaded(value: boolean) {
        this._inited = value;
    }

    @computed
    public get isLoggedIn(): boolean {
        return null !== this._user;
    }

    @computed
    public get token(): string {
        return this._token;
    }

    @computed
    public get hasToken(): boolean {
        return !!this._token;
    }

    public set token(token: string) {
        this._token = token;
    }

    @computed
    public get accesses(): SystemUserAccess[] {
        return this._accesses;
    }

    @action
    public setToken(token: string) {
        this.token = token;
    }

    @action
    public setUser(user: SystemUser | null) {
        this.user = user;
    }

    @action
    public setInited(value: boolean): void {
        this.loaded = value;
    }

    @action
    public setClosed(value: boolean): void {
        this.closed = value;
    }

    @action
    public setSelectedCurrencyCode(value: string): void {
        this.selectedCurrencyCode = value;
    }

    @action
    public setCurrencyCodes(value: string[]): void {
        this.currencyCodes = value;
    }

    @action
    public setAttributes(attributes: string[]): void {
        this.attributes = attributes;
    }

    @action
    public setIsInvalidCredentials(value: boolean | null): void {
        this.isInvalidCredentials = value;
    }

    @action
    public setLogging(value: boolean): void {
        this.isLogging = value;
    }

    @action
    public getAccess(): number {
        return this._access;
    }

    @action
    public setAccess(value: number | null) {
        if (this._access !== value) {
            this._access = value || 0;
        }
    }

    @action
    public getAccesses(): SystemUserAccess[] {
        return this._accesses;
    }

    @action
    public setAccesses(value: SystemUserAccess[]) {
        this._accesses = value;
    }

    @action
    public setOriginalUsername(value: string | undefined) {
        this._originalUsername = value;
    }

    @computed
    public get isUserFaked(): boolean {
        return !!this.originalUsername;
    }

    @computed
    public get originalUsername(): string | undefined {
        return this._originalUsername;
    }

    @action
    public setLZ(value: LZ) {
        this._lz = value;
    }

    get lz(): LZ {
        return this._lz;
    }

    set lz(value: LZ) {
        this._lz = value;
    }
}
