import {ColumnsType} from "antd/lib/table";
import {InvoicesTableItem} from "../../../models/invoices/invoice";
import {Link} from "react-router-dom";
import {Warehouse} from "../../../models/warehoues/warehouse";
import {Amount} from "../../../models/amount/amount";
import {Price} from "../../core/commons/price/price";
import {Date} from "../../core/commons/date/date";
import {InvoicePayButton} from "../invoice/common/pay-button/pay-button";
import React from "react";
import {TFunction} from "react-i18next";

export const columnsFactory = (t: TFunction<"translation">, allowPayments: boolean) => {
    const columns: ColumnsType<InvoicesTableItem> = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            render: (id: string) => (
                <Link className="link" to={"/invoices/" + id}>
                    {id}
                </Link>
            ),
        },
        {
            title: t("INVOICE.NUMBER"),
            dataIndex: "docNumber",
            key: "invoices_number",
            render: (item: string) => <span style={{ whiteSpace: "nowrap" }}>{item}</span>,
        },
        {
            title: t("WAREHOUSES.WAREHOUSE_TITLE"),
            dataIndex: "warehouse",
            key: "invoices_warehouse",
            render: (warehouse: Warehouse) => warehouse.name,
        }
    ];

    if (!allowPayments) {
        columns.push(
            {
                title: t("PRICE"),
                dataIndex: "subtotal",
                key: "invoices_subtotal",
                render: (amount: Amount) => <Price value={amount}/>,
            },
            {
                title: "VAT",
                dataIndex: "subtotalVat",
                key: "invoices_subtotalVat",
                render: (amount: Amount) => <Price value={amount}/>,
            }
        );
    }

    columns.push(
        {
            title: t("GRAND_TOTAL_PRICE"),
            dataIndex: "grandTotal",
            key: "invoices_grandTotal",
            render: (total: Amount) => <Price value={total} />,
        },
        {
            title: t("CREATED_AT"),
            dataIndex: "createdAt",
            key: "invoices_createdAt",
            render: (date: string) => <Date dateString={date} showTime />,
        }
    );

    if (allowPayments) {
        columns.push(
            {
                title: t("PAY"),
                dataIndex: "id",
                key: "id",
                render: (_: any, date) => <InvoicePayButton invoice={date} />,
            }
        );
    }

    return columns;
}