import React, {useContext, useEffect, useMemo, useState} from "react";
import { Button, Dropdown, Menu, Space } from "antd";
import { Link } from "react-router-dom";
import FilteredTable from "../../core/commons/filtered-table/filtered.table";
import { ordersService } from "../../../services/orders/orders.service";
import { Amount } from "../../../models/amount/amount";
import { Date } from "../../core/commons/date/date";
import { useTranslation } from "react-i18next";
import { FilterKey } from "../../../models/filtered-table/filtered.table";
import { OrderStatus, OrderStatusType, statusColor } from "../order-status/order.status";
import { Warehouse } from "../../../models/warehoues/warehouse";
import { warehousesService } from "../../../services/warehouses/warehouses.service";
import { OrdersListStore } from "./orders.list.store";
import { OrdersListController } from "./orders.list.controller";
import { observer } from "mobx-react";
import { ComponentPropsFromRoute } from "../../../routers/routers";
import { NotCompletedOrderAmount } from "../../core/commons/not-completed-order-ammount/not.completed.order.amount";
import { ColumnsType } from "antd/lib/table";
import { OrderDocument } from "../../../models/documents/documents";
import {OrderUser} from "../../../models/orders/order/order";
import {Price} from "../../core/commons/price/price";
import {convertTableColumnsToColumnConfigs} from "../../../utils/table";
import {columnsFactory} from "./orders.list.columns";
import {RootStoreContext} from "../../../stores/root/root";

interface OrdersListProps extends ComponentPropsFromRoute {}
export const OrdersList: React.FC<OrdersListProps> = observer(({ name, requiredConfigs }) => {

    const [warehouses, setWarehouses] = useState<Warehouse[]>([]);
    const [store] = useState(() => new OrdersListStore(requiredConfigs!.type));
    const [controller] = useState(() => new OrdersListController(store, ordersService));

    useEffect(() => {
        try {
            warehousesService.getWarehouses().then((warehouses) => setWarehouses(warehouses));
        } catch (e) {
            console.log(e);
        }
    }, []);

    const {
        authStore: { lz },
    } = useContext(RootStoreContext);

    const { t } = useTranslation("translation", { useSuspense: false });
    const columns = useMemo(() => columnsFactory(t, lz.py), []);

    const filters: FilterKey[] = [
        {
            key: "id",
            label: "ID",
            type: "string",
        },
        {
            key: "warehouses",
            label: t("WAREHOUSES.WAREHOUSE_TITLE"),
            type: "select",
            options: warehouses.map((item) => ({ label: item.name, value: item.id })),
        },
        {
            key: "status",
            label: t("ORDERS.STATUS.TITLE"),
            type: "select",
            options: Object.keys(statusColor).map((item) => ({
                value: item,
                label: t("ORDERS.ORDER.STATUS." + item.toUpperCase()),
            })),
        },
        {
            key: "grandTotal",
            label: t("GRAND_TOTAL_PRICE"),
            type: "int",
        },
        {
            key: "clientOrderId",
            label: t("ORDERS.ALT_NUMBER"),
            type: "string",
        },
        {
            key: "createdAt",
            label: t("CREATED_AT"),
            type: "date",
        },
    ];


    return (
        <Space direction={"vertical"} size={[15, 15]} className="content-inner">
            <Space direction={"horizontal"} size={[10, 10]}>
                {}
            </Space>
            {!store.dataHandler.loading ? <NotCompletedOrderAmount /> : null}
            <FilteredTable
                rowKey="id"
                filterKeys={filters}
                columns={columns}
                columnsConfigs={{ columns: convertTableColumnsToColumnConfigs(columns), pageKey: name! }}
                getRequest={controller.setRequest}
                dataHandler={store.dataHandler}
            />
        </Space>
    );
});
