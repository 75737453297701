import React, {useContext} from "react";
import {Link} from "react-router-dom";
import {Button, Tag, Tooltip} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Price} from "../../../../core/commons/price/price";
import {RootStoreContext} from "../../../../../stores/root/root";
import {Amount} from "../../../../../models/amount/amount";
import {InvoicePaymentStatus} from "../../invoice-payment-status/invoice-payment.status";
import { QuestionCircleOutlined } from '@ant-design/icons';

interface InvoiceValues {
    id: number;
    paid: boolean;
    allowToPay: boolean;
    grandTotal: Amount;
}

interface InvoicePayButtonProps {
    invoice: InvoiceValues;
}

export const InvoicePayButton: React.FC<InvoicePayButtonProps> = ({invoice}) => {

    const {
        authStore: { lz },
    } = useContext(RootStoreContext);

    if (!lz.py) {
        return null;
    }

    return (
        <>{(invoice.paid) ? (
            <InvoicePaymentStatus paid={invoice.paid} />
        ) : (
            <>{invoice.allowToPay ? (
                <Link to={"/api/v3/payments/invoice/" + invoice.id} target={"_blank"}>
                    <Button
                        icon={<FontAwesomeIcon icon={["fas", "credit-card"]} />}
                        type={"primary"}
                        size={"small"}
                    >
                        <span style={{padding: "0 3px 0 10px"}}>Pay</span>
                        <Price value={invoice.grandTotal} />
                    </Button>
                </Link>
            ) : (
                <Tag
                    color={"yellow"}
                >
                    <Tooltip title="Payment will be available only after the goods have been shipped">
                        <QuestionCircleOutlined style={{ marginRight: 8 }} />
                    </Tooltip>
                    Payment not available
                </Tag>
            )}</>
        )}</>
    )
}