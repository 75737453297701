import {ColumnsType} from "antd/lib/table";
import {Link} from "react-router-dom";
import {Warehouse} from "../../../models/warehoues/warehouse";
import {Amount} from "../../../models/amount/amount";
import {Price} from "../../core/commons/price/price";
import {Date} from "../../core/commons/date/date";
import React from "react";
import {TFunction} from "react-i18next";
import {OrderStatus, OrderStatusType} from "../order-status/order.status";
import {Order, OrderUser} from "../../../models/orders/order/order";
import {OrderPayButton} from "../order/common/pay-button/pay-button";

export const columnsFactory = (t: TFunction<"translation">, allowPayments: boolean) => {
    const columns: ColumnsType<Order> = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            render: (id: string) => (
                <Link className="link" to={"/order/" + id}>
                    {id}
                </Link>
            ),
        },
        {
            title: t("ORDERS.STATUS.TITLE"),
            dataIndex: "status",
            key: "orders_status",
            render: (status: OrderStatusType) => <OrderStatus status={status} />,
        },
        {
            title: t("GRAND_TOTAL_PRICE"),
            dataIndex: "grandTotal",
            key: "orders_grandTotal",
            render: (total: Amount) => <Price value={total} />,
        },
        {
            title: t("WAREHOUSES.WAREHOUSE_TITLE"),
            dataIndex: "warehouse",
            key: "orders_warehouse",
            render: (warehouse: Warehouse) => <>{warehouse.name}</>,
        },
        {
            title: t("ORDERS.ALT_NUMBER"),
            dataIndex: "clientOrderId",
            key: "orders_clientOrderId",
        }
    ];

    if (!allowPayments) {
        columns.push(
            {
                title: t("ORDERS.USER"),
                dataIndex: "user",
                key: "orders_user",
                render: (user: OrderUser) => user.lastName + " " + user.firstName,
            }
        )
    }

    columns.push(
        {
            title: t("CREATED_AT"),
            dataIndex: "createdAt",
            key: "orders_createdAt",
            render: (date: string) => <Date dateString={date} showTime />,
        }
    );

    if (allowPayments) {
        columns.push(
            {
                title: t("PAY"),
                dataIndex: "id",
                key: "id",
                render: (_: any, item) => <OrderPayButton order={item} />,
                align: "left"
            }
        )
    }

    return columns;
}