import React, {useContext} from "react";
import {Link} from "react-router-dom";
import {Button, Space, Tag, Tooltip} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {RootStoreContext} from "../../../../../stores/root/root";
import {Price} from "../../../../core/commons/price/price";
import {Order} from "../../../../../models/orders/order/order";
import {InvoicePaymentStatus} from "../../../../invoices/invoice/invoice-payment-status/invoice-payment.status";
import {QuestionCircleOutlined} from "@ant-design/icons";

interface OrderPayButtonProps {
    order: Order;
}

export const OrderPayButton: React.FC<OrderPayButtonProps> = ({order}) => {

    const {
        authStore: { lz },
    } = useContext(RootStoreContext);

    if (!lz.py) {
        return null;
    }

    return (

        <>{order.paid ? (
            <InvoicePaymentStatus paid={order.paid} />
        ) : (
            <>{order.allowToPay ? (
                <Space direction={"horizontal"}>
                    <Link to={"/api/v3/payments/order/" + order.id} target={"_blank"}>
                        <Button
                            icon={<FontAwesomeIcon icon={["fas", "credit-card"]}/>}
                            type={"primary"}
                            size={"small"}
                        >
                            <span style={{padding: "0 3px 0 10px"}}>Pay</span>
                            <Price value={order.grandTotal}/>
                        </Button>
                    </Link>
                </Space>
            ) : (
                <Tag
                    color={"yellow"}
                >
                    <Tooltip title="You can pay in a few minutes when the warehouse confirms the availability of the goods">
                        <QuestionCircleOutlined style={{ marginRight: 8 }} />
                    </Tooltip>
                    Payment not available
                </Tag>
            )}
            </>
        )}</>
    )
}