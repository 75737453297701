import React, {useContext} from "react";
import {Link} from "react-router-dom";
import {Alert, Button, Space} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {RootStoreContext} from "../../../../../stores/root/root";
import {Price} from "../../../../core/commons/price/price";
import {Amount} from "../../../../../models/amount/amount";

interface InvoiceValues {
    id: number;
    paid: boolean;
    allowToPay: boolean;
    grandTotal: Amount;
}

interface InvoicePayButtonProps {
    invoice: InvoiceValues;
}

export const InvoicePayWidget: React.FC<InvoicePayButtonProps> = ({invoice}) => {

    const {
        authStore: { lz },
    } = useContext(RootStoreContext);

    if (!lz.py) {
        return null;
    }

    return (
        <>{!invoice.paid ? (<>
                {invoice.allowToPay ? (
                    <Space style={{justifyContent: "end"}} direction={"horizontal"}>
                        <Link to={"/api/v3/payments/invoice/" + invoice.id} target={"_blank"}>
                            <Button
                                icon={<FontAwesomeIcon icon={["fas", "credit-card"]}/>}
                                type={"primary"}
                            >
                                <span style={{padding: "0 3px 0 10px"}}>Pay</span>
                                <Price value={invoice.grandTotal}/>
                            </Button>
                        </Link>
                    </Space>
                ) : (
                    <Alert
                        showIcon
                        description={"Payment will be available only after the goods have been shipped"}
                        message={"Thanks for your order!"}
                    />
                )}
            </>
        ) : null}
        </>
    )
}