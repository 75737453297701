import React, {useContext, useEffect, useMemo, useState} from "react";
import { Space } from "antd";
import { useTranslation } from "react-i18next";
import { FilterKey } from "../../../models/filtered-table/filtered.table";
import FilteredTable from "../../core/commons/filtered-table/filtered.table";
import { invoicesService } from "../../../services/invoices/invoices.service";
import { Warehouse } from "../../../models/warehoues/warehouse";
import { warehousesService } from "../../../services/warehouses/warehouses.service";
import { ComponentPropsFromRoute } from "../../../routers/routers";
import {convertTableColumnsToColumnConfigs} from "../../../utils/table";
import {RootStoreContext} from "../../../stores/root/root";
import {columnsFactory} from "./invoices.list.columns";

interface InvoicesListProps extends ComponentPropsFromRoute {}
export const InvoicesList: React.FC<InvoicesListProps> = ({ name }) => {

    const [warehouses, setWarehouses] = useState<Warehouse[]>([]);

    const {
        authStore: { lz },
    } = useContext(RootStoreContext);

    useEffect(() => {
        try {
            warehousesService.getWarehouses().then((warehouses) => setWarehouses(warehouses));
        } catch (e) {
            console.log(e);
        }
    }, []);

    const { t } = useTranslation("translation", { useSuspense: false });
    const columns = useMemo(() => columnsFactory(t, lz.py), [])

    const filters: FilterKey[] = [
        {
            key: "id",
            label: "ID",
            type: "string",
        },
        {
            key: "orderId",
            label: t("ORDERS.ORDER.TITLE"),
            type: "string",
        },
        {
            key: "number",
            label: t("INVOICES.NUMBER"),
            type: "string",
        },
        {
            key: "packingList",
            label: t("PACKING_LIST"),
            type: "string",
        },
        {
            key: "warehouses",
            label: t("WAREHOUSES.WAREHOUSE_TITLE"),
            type: "select",
            options: warehouses.map((item) => ({ label: item.name, value: item.id })),
        },
        {
            key: "grandTotal",
            label: t("TOTAL_AMOUNT"),
            type: "int",
        },
        {
            key: "clientPaymentTerms",
            label: t("PAYMENT_TERMS"),
            type: "int",
        },
        {
            key: "createdAt",
            label: t("CREATED_AT"),
            type: "date",
        },
    ];

    return (
        <Space direction="vertical" size={[30, 30]} className="content-inner">
            <FilteredTable
                rowKey="id"
                filterKeys={filters}
                columns={columns}
                columnsConfigs={{ columns: convertTableColumnsToColumnConfigs(columns), pageKey: name! }}
                loadDataCallback={(request) => invoicesService.getInvoices(request)}
            />
        </Space>
    );
};
