import React, {useContext, useState} from "react";
import { observer } from "mobx-react";
import { useRequiredLayers } from "../../../../utils/context";
import { OrderDataStore, OrderDataStoreContext } from "../order.data.store";
import { OrderDataController, OrderDataControllerContext } from "../order.data.controller";
import { useTranslation } from "react-i18next";
import { Button, Dropdown, Menu, Space } from "antd";
import { Link } from "react-router-dom";
import { RootStoreContext } from "../../../../stores/root/root";
import { isAllowed } from "../../../../utils/helpers";
import {Export} from "../../../core/commons/export/export";

export const OrderHeaderDocumentButtons: React.FC = observer(() => {
    const {
        authStore: { attributes },
    } = useContext(RootStoreContext);
    const { store, controller } = useRequiredLayers<OrderDataStore, OrderDataController>(
        OrderDataStoreContext,
        OrderDataControllerContext
    );
    const { t } = useTranslation("translation", { useSuspense: false });

    const allowed = isAllowed(attributes, "merged_invoice_list");

    const [orderExportModalShown, setOrderExportModalShown] = useState<boolean>(false);

    return store.order?.documents.proforma ||
        store.order?.documents.invoice ||
        store.order?.documents.packing_list ||
        store.order?.documents.merged_invoice ? (
        <>
            <Space wrap direction="horizontal" size={[10, 5]}>

                <Button
                    onClick={() => setOrderExportModalShown(true)}
                >
                    {t("DOWNLOAD.CSV_EXCEL")}
                </Button>

                {!isAllowed(attributes, "merged_invoice_list") || store.order?.clientPaymentTerms === 0 ? (
                    <>
                        {!store.order?.documents.proforma ? null : store.order.documents.proforma.length > 1 ? (
                            <Dropdown
                                placement={"bottomRight"}
                                trigger={["click"]}
                                overlay={
                                    <Menu>
                                        {store.order.documents.proforma.map((proforma) => (
                                            <Menu.Item key={proforma.id} style={{ padding: "4px 15px" }}>
                                                <a target="_blank" rel="noopener noreferrer" href={"/documents/" + proforma.id}>
                                                    {proforma.docNumber}
                                                </a>
                                            </Menu.Item>
                                        ))}
                                    </Menu>
                                }
                            >
                                <Button> {t("ORDERS.ORDER.VIEW.PROFORMA")}</Button>
                            </Dropdown>
                        ) : (
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                className="ant-btn"
                                href={"/documents/" + store.order?.documents.proforma[0].id}
                            >
                                {t("ORDERS.ORDER.VIEW.PROFORMA")}
                            </a>
                        )}
                    </>) : null }

                {!store.order?.documents.packing_list ? null : store.order.documents.packing_list.length > 1 ? (
                    <Dropdown
                        placement={"bottomRight"}
                        trigger={["click"]}
                        overlay={
                            <Menu>
                                {store.order.documents.packing_list.map((packingList) => (
                                    <Menu.Item key={packingList.id} style={{ padding: "4px 15px" }}>
                                        <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href={"/documents/" + packingList.id}
                                        >
                                            {packingList.docNumber}
                                        </a>
                                    </Menu.Item>
                                ))}
                            </Menu>
                        }
                    >
                        <Button>{t("ORDERS.ORDER.VIEW.PACKING_LIST")}</Button>
                    </Dropdown>
                ) : (
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        className={"ant-btn"}
                        href={"/documents/" + store.order?.documents.packing_list[0].id}
                    >
                        {t("ORDERS.ORDER.VIEW.PACKING_LIST")}
                    </a>
                )}

                {allowed ? null : !store.order?.documents.invoice ? null : store.order.documents.invoice?.length > 1 ? (
                    <Dropdown
                        placement={"bottomRight"}
                        trigger={["click"]}
                        overlay={
                            <Menu>
                                {store.order.documents.invoice.map((invoice) => (
                                    <Menu.SubMenu key={invoice.id} title={invoice.docNumber}>
                                        <Menu.Item key={0} style={{ padding: "4px 13px" }}>
                                            <Link
                                                style={{ textAlign: "left" }}
                                                //className={"ant-btn ant-btn-link"}
                                                to={"/invoices/" + invoice.id}
                                            >
                                                {t("ORDERS.ORDER.VIEW.INVOICE")}
                                            </Link>
                                        </Menu.Item>
                                        <Menu.Item key={1}>
                                            <a
                                                style={{ textAlign: "left" }}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className={"ant-btn ant-btn-link"}
                                                href={"/documents/" + invoice.id}
                                            >
                                                {t("DOCUMENTS.FILES.SHOW_PDF")}
                                            </a>
                                        </Menu.Item>
                                        <Menu.Item
                                            style={{ textAlign: "left" }}
                                            key={2}
                                            onClick={() => controller.openExportModal(invoice.id)}
                                        >
                                            {t("DOWNLOAD.CSV_EXCEL")}
                                        </Menu.Item>
                                    </Menu.SubMenu>
                                ))}
                            </Menu>
                        }
                    >
                        <Button> {t("ORDERS.ORDER.VIEW.INVOICE")}</Button>
                    </Dropdown>
                ) : (
                    <Dropdown
                        placement={"bottomRight"}
                        trigger={["click"]}
                        overlay={
                            <Menu>
                                <Menu.Item key={0} style={{ padding: "4px 13px" }}>
                                    <Link
                                        style={{ textAlign: "left" }}
                                        //className={"ant-btn ant-btn-link"}
                                        to={"/invoices/" + store.order.documents.invoice[0].id}
                                    >
                                        {t("ORDERS.ORDER.VIEW.INVOICE")}
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key={1}>
                                    <a
                                        style={{ textAlign: "left" }}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className={"ant-btn ant-btn-link"}
                                        href={"/documents/" + store.order.documents.invoice[0].id}
                                    >
                                        {t("DOCUMENTS.FILES.SHOW_PDF")}
                                    </a>
                                </Menu.Item>
                                <Menu.Item
                                    style={{ textAlign: "left" }}
                                    key={2}
                                    onClick={() => controller.openExportModal(store.order!.documents.invoice![0].id)}
                                >
                                    {t("DOWNLOAD.CSV_EXCEL")}
                                </Menu.Item>
                            </Menu>
                        }
                    >
                        <Button> {t("ORDERS.ORDER.VIEW.INVOICE")}</Button>
                    </Dropdown>
                )}

                {!allowed ? null : !store.order.documents.merged_invoice ? null : store.order.documents.merged_invoice
                      .length > 1 ? (
                    <Dropdown
                        placement={"bottomRight"}
                        trigger={["click"]}
                        overlay={
                            <Menu>
                                {store.order.documents.merged_invoice.map((mergedInvoice) => (
                                    <Menu.SubMenu key={mergedInvoice.id} title={mergedInvoice.docNumber}>
                                        <Menu.Item key={0} style={{ padding: "4px 13px" }}>
                                            <Link
                                                style={{ textAlign: "left" }}
                                                //className={"ant-btn ant-btn-link"}
                                                to={"/final-invoices/" + mergedInvoice.id}
                                            >
                                                {t(
                                                    allowed
                                                        ? "ORDERS.ORDER.VIEW.INVOICE"
                                                        : "ORDERS.ORDER.VIEW.MERGED_INVOICE"
                                                )}
                                            </Link>
                                        </Menu.Item>
                                        <Menu.Item key={1}>
                                            <a
                                                style={{ textAlign: "left" }}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className={"ant-btn ant-btn-link"}
                                                href={"/documents/" + mergedInvoice.id}
                                            >
                                                {t("DOCUMENTS.FILES.SHOW_PDF")}
                                            </a>
                                        </Menu.Item>
                                        {/* <Menu.Item
                                            style={{ textAlign: "left" }}
                                            key={2}
                                            onClick={() => controller.openExportModal(mergedInvoice.id)}
                                        >
                                            {t("DOWNLOAD.CSV_EXCEL")}
                                        </Menu.Item> */}
                                    </Menu.SubMenu>
                                    // <Menu.Item key={mergedInvoice.id} style={{ padding: "4px 13px" }}>
                                    //     <a
                                    //         style={{ textAlign: "left" }}
                                    //         target="_blank"
                                    //         rel="noopener noreferrer"
                                    //         className={"ant-btn ant-btn-link"}
                                    //         href={"/documents/" + mergedInvoice.id}
                                    //     >
                                    //         {mergedInvoice.docNumber}
                                    //     </a>
                                    // </Menu.Item>
                                ))}
                            </Menu>
                        }
                    >
                        <Button>{t(allowed ? "ORDERS.ORDER.VIEW.INVOICE" : "ORDERS.ORDER.VIEW.MERGED_INVOICE")}</Button>
                    </Dropdown>
                ) : (
                    <Dropdown
                        placement={"bottomRight"}
                        trigger={["click"]}
                        overlay={
                            <Menu>
                                <Menu.Item key={0} style={{ padding: "4px 13px" }}>
                                    <Link
                                        style={{ textAlign: "left" }}
                                        //className={"ant-btn ant-btn-link"}
                                        to={"/final-invoices/" + store.order.documents.merged_invoice[0].id}
                                    >
                                        {t("ORDERS.ORDER.VIEW.INVOICE")}
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key={1}>
                                    <a
                                        style={{ textAlign: "left" }}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className={"ant-btn ant-btn-link"}
                                        href={"/documents/" + store.order.documents.merged_invoice[0].id}
                                    >
                                        {t("DOCUMENTS.FILES.SHOW_PDF")}
                                    </a>
                                </Menu.Item>
                                {/* <Menu.Item
                                    style={{ textAlign: "left" }}
                                    key={2}
                                    onClick={() => controller.openExportModal(store.order!.documents.invoice![0].id)}
                                >
                                    {t("DOWNLOAD.CSV_EXCEL")}
                                </Menu.Item> */}
                            </Menu>
                        }
                    >
                        <Button>{t(allowed ? "ORDERS.ORDER.VIEW.INVOICE" : "ORDERS.ORDER.VIEW.MERGED_INVOICE")}</Button>
                    </Dropdown>
                )}

                {}
            </Space>
            {orderExportModalShown ? (
                <Export
                    title={"ORDERS.ORDER.DOWNLOAD_CSV_INVOICE"}
                    isShown={true}
                    withBasicExportFields
                    config={{
                        link: "/api/v3/orders/" + store.order!.id
                    }}
                    onClose={() => setOrderExportModalShown(false)}
                />
            ) : null}
        </>
    ) : null;
});
