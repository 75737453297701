import {Row, Col, Table, Space} from "antd";
import React from "react";
import "./double-details.table.footer.scss";
import {useTranslation} from "react-i18next";
import {splitNumber} from "../../../../models/amount/amount.helper";
import {NotCompletedOrderSummary} from "../../../../models/cart/checkout/not.completed.order";
import {Price} from "../price/price";

interface DoubleDetailsTableFooterProps {
    backorderSummary: NotCompletedOrderSummary;
    stockSummary: NotCompletedOrderSummary;
    vatPercent: number;
    additional?: React.ReactNode;
}

export const DoubleDetailsTableFooter: React.FC<DoubleDetailsTableFooterProps> = ({
                                                                                   backorderSummary,
                                                                                   stockSummary,
                                                                                   vatPercent,
                                                                                   additional
                                                                               }) => {

    const {t} = useTranslation('translation', {useSuspense: false});

    const columns = [];
    columns.push({title: '', dataIndex: 'title'})
    if (backorderSummary.qty > 0) {
        columns.push({title: 'Back Order', dataIndex: 'backOrderValue'})
    }
    columns.push({title: 'Stock Order', dataIndex: 'stockValue'})

    return (
        <Row justify="end">
            <Col xxl={9} xl={10} md={12} sm={24} xs={24}>
                <Space direction={"vertical"}>
                    <Table
                        className={"order-checkout-table-summary"}
                        columns={columns}
                        dataSource={[
                            {
                                title: t("SHIPPED_QUANTITY"),
                                stockValue: splitNumber(stockSummary.qty) + t("PCS"),
                                backOrderValue: splitNumber(backorderSummary.qty) + t("PCS")
                            },
                            {
                                title: t("TOTAL_WEIGHT"),
                                stockValue: <>{String(stockSummary.totalNetWeight).replaceAll(".", ",")} ({t("KG")})</>,
                                backOrderValue: <>{String(backorderSummary.totalNetWeight).replaceAll(".", ",")} ({t("KG")})</>
                            },
                            {
                                title: t("TOTAL_PRICE"),
                                stockValue: <Price value={stockSummary.subtotal} />,
                                backOrderValue: <Price value={backorderSummary.subtotal} />
                            },
                            {
                                title: "VAT(" + vatPercent + "%)",
                                stockValue: <Price value={stockSummary.subtotalVat} />,
                                backOrderValue: <Price value={backorderSummary.subtotalVat} />
                            },
                            {
                                title: t("GRAND_TOTAL_PRICE"),
                                stockValue: <Price value={stockSummary.grandTotal} />,
                                backOrderValue: <Price value={backorderSummary.grandTotal} />
                            }
                        ]}
                        pagination={false}
                    >
                    </Table>
                    {additional}
                </Space>
            </Col>
        </Row>
    );
};
