import React, {useContext} from "react";
import {Link} from "react-router-dom";
import {Alert, Button, Space} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {RootStoreContext} from "../../../../../stores/root/root";
import {OrderData} from "../../../../../models/orders/order-data/order.data";
import {Price} from "../../../../core/commons/price/price";

interface OrderPayButtonProps {
    order: OrderData;
}

export const OrderPayWidget: React.FC<OrderPayButtonProps> = ({order}) => {

    const {
        authStore: { lz },
    } = useContext(RootStoreContext);

    if (!lz.py) {
        return null;
    }

    return (
        <>{!order.paid ? (<>
                {order.allowToPay ? (
                    <Space style={{justifyContent: "end"}} direction={"horizontal"}>
                        <Link to={"/api/v3/payments/order/" + order.id} target={"_blank"}>
                            <Button
                                icon={<FontAwesomeIcon icon={["fas", "credit-card"]}/>}
                                type={"primary"}
                            >
                                <span style={{padding: "0 3px 0 10px"}}>Pay</span>
                                <Price value={order.stockSummary.grandTotal}/>
                            </Button>
                        </Link>
                    </Space>
                ) : (
                    <Alert
                        showIcon
                        description={"You can pay in a few minutes when the warehouse confirms the availability of the goods"}
                        message={"Thanks for your order!"}
                    />
                )}
            </>
            ) : null}
        </>
    )
}