import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Tag} from "antd";
import "./catalog.product.cart.stock-info.scss"
import {CatalogArticle} from "../../../../../models/articles/catalog/search";
import {Date} from "../../../../core/commons/date/date";
interface CatalogProductCartStockInfoProps {
    article: CatalogArticle;
}

export const CatalogProductCartStockInfo: React.FC<CatalogProductCartStockInfoProps> = ({article}) => {

    return (
        <>
            <div className={"product-stock-info"}>
                <div className={"product-stock-info-title"}>
                    <FontAwesomeIcon
                        className="warehouses_col-icon"
                        icon={["fas", "cubes"]}
                    /> Stock Info
                </div>
                <div className="product-stock-info-stocks">
                    {article.stock.map((value, index) => {
                        return (
                            <Tag
                                className={undefined}
                                key={index}
                                title={value.warehouse}
                            >
                                <strong>{value.amount}</strong>
                                <span>{value.warehouse}</span>
                            </Tag>
                        );
                    })}
                </div>
            </div>
            {article.inTransit ? (
                <div className={"product-stock-info"}>
                    <div className={"product-stock-info-title"}>
                        <FontAwesomeIcon
                            className="warehouses_col-icon"
                            icon={["fas", "truck"]}
                        /> Transit
                    </div>
                    <div className="product-stock-info-stocks">
                        <Tag key={1}>
                            <>
                                <strong>{article.inTransit.qty}</strong>
                                <span>(Will arrive approximately <Date dateString={article.inTransit.minimalDate} noStyle />
                                    {article.inTransit.maximalDate !== article.inTransit.minimalDate ? (
                                        <> ... <Date dateString={article.inTransit.maximalDate} noStyle /></>
                                    ) : null}
                                    )
                                                        </span>
                            </>
                        </Tag>
                    </div>
                </div>
            ) : null}
        </>
    )
}